<template>
  <transition-page>
    <router-view />
  </transition-page>
</template>

<script>
import TransitionPage from "@/components/Layout/TransitionPage";

export default {
  name: "transactions",
  components: {
    TransitionPage,
  },
  props: [],
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss">
@import "Transactions";
</style>
